.filepond--credits {
    display: none !important;
}

.filepond--list-scroller[data-state=overflow] {
    height: 100px !important;
}

.filepond--file {
    background-color: #6c6fbe !important;
    height: max-content !important;
}

.filepond--wrapper {
    background-color: transparent !important;
    width: 100% !important;
}

.filepond--root {
    background-color: transparent !important;
    width: 100% !important;
    border: dashed black 2px !important;
    border-radius: 1.5em;
}

.filepond--hopper {
    background-color: transparent !important;
    width: 100% !important;
    height: 100px !important;
}

.filepond--drop-label {
    /* background-color: transparent !important; */
    width: 100% !important; 
    border-radius: 1.5em;
    color: #8a8989 !important;
}
.filepond--panel-center {
    background-color: transparent !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filepond--panel-root {
    width: 100% !important;
    background-color: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filepond {
    background-color: #d1d1d1 !important;
    width: 100% !important;
    display: none;
}

.mobile-container  {
    display: none;
}
.add-button {
    display: none;
}
.formProfile {
    background-color: transparent !important;
}

.changeUserAvatar {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    color: #2d3192;
    font-size: 1.8em;
    font-weight: bolder;
    position:sticky;
    left: 27.5%;
    bottom: 50%;
    cursor: pointer;
}

.buttons-container {
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    column-gap: 10px;
    row-gap: 25px;
    position: relative;
    top: 0%
}

.container {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 1.5em;
}

.user-box {
    margin-top: 15px;
}

.imageContenedor {
    width: 40%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.8em;
    line-height: 2px;
    background: #FF416C;
	background: -webkit-linear-gradient(to right, #FF416C, #2d3192);
	background: linear-gradient(to right, #FF416C, #2d3192);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
}
.imageContenedor h1 {
    font-size: 2em;
    padding-top: 25px;
    color: #FFFFFF;
}
.imageContenedor h3 {
    font-size: 1.5em;
    font-weight: 500;
    color: #FFFFFF;
}
.imageProfile {
    background: #202435;
    color: #FFFFFF;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.userAvatar {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    object-fit: cover;
    /* object-fit: scale-down; */
}
.inputsContainer {
    width: 50%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.8em;
    background-color: #FFFFFF;
    /* background: #FF416C;
	background: -webkit-linear-gradient(to right, #FF416C, #2d3192);
	background: linear-gradient(to right, #FF416C, #2d3192);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0; */
}

input {
	background-color: #d1d1d1;
	border: none;
	padding: 12px 15px;
	margin: 5px 0;
	width: 350px;
	border-radius: 15px;
}

.profile-box div {
    padding-bottom: 10px;
}

.profile-box h1 {
    margin-bottom: 35px;
}

.profile-box h3 {
    font-size: 1em;
    cursor: pointer;
}

.profile-box h3 a {
    text-decoration: none;
    font-size: 1em;
}

.button {
    background: #202435;
    border: 0;
    color: #f4f7ff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 40px;
    outline: none;
    perspective: 100px;
    position: relative;
    text-align: center;
    border-radius: 1em;
    width: 200px;
    -webkit-tap-highlight-color: transparent;
    z-index: 0;
}

.confirm-button {
    background: #202435;
    border: 0;
    color: #f4f7ff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 40px;
    outline: none;
    perspective: 100px;
    position: relative;
    text-align: center;
    border-radius: 1em;
    width: 200px;
    -webkit-tap-highlight-color: transparent;
    z-index: 0;
}

.cancelButton {
    background: #FF416C;
    border: 0;
    color: #f4f7ff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 40px;
    outline: none;
    perspective: 100px;
    position: relative;
    text-align: center;
    border-radius: 1em;
    width: 200px;
    -webkit-tap-highlight-color: transparent;
    z-index: 0;
}

form {
    background: transparent !important;
}

@media (max-width: 1050px) {
    .update-data {
        display: none;
    }

    .button {
        width: 150px;
    }
    
    .cancelButton {
        width: 150px;
    }
    
    .confirm-button {
        width: 150px;
    }

    .container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        overflow-x: auto;
    }

    .imageContenedor {
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 20px;
        column-gap: 20px;
        border-radius: 0.8em 0.8em 0 0;
    }

    /* .button {
        display: none;
    } */

    .add-button {
        display: flex;
        background-color: #2d3192;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        font-weight: bolder;
        color: #FFFFFF;
        font-size: 2em;
        top: 22.5%;
        border: solid #FFFFFF 2px;
        cursor: pointer;
    }

    .imageContenedor h1 {
        font-size: 1.5em;
        padding-top: 25px;
        color: #FFFFFF;
    }

    .imageContenedor h3 {
        font-size: 1em;
        font-weight: 500;
        color: #FFFFFF;
    }

    .inputsContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 0 0 0.8em 0.8em;
        padding: 20px;
    }
    .changeUserAvatar {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        color: #2d3192;
        font-size: 1.8em;
        font-weight: bolder;
        position:absolute;
        left: 37.5%;
        bottom: 75%;
        cursor: pointer;
    }
}

@media (max-width: 470px) {

    /* .data-container {
        display: none;
    } */
    .changeUserAvatar {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        color: #2d3192;
        font-size: 1.8em;
        font-weight: bolder;
        position: absolute;
        left: 40%;
        bottom: 77.5%;cursor: pointer;
    }

    .buttons-container {
        padding-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        column-gap: 10px;
        position: relative;
        bottom: 20%
    }

    .image {
        background: transparent;
        color: #FFFFFF;
        width: 150px;
        height: 150px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .userAvatar {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        object-fit: cover;
        /* object-fit: scale-down; */
    }

    .imageProfile {
        background: transparent;
        color: #FFFFFF;
        width: 150px;
        height: 150px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .add-button {
        display: flex;
        background-color: #2d3192;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        font-weight: bolder;
        color: #FFFFFF;
        font-size: 1.5em;
        top: 19.5%;
        left: 42.5%;
        border: solid #FFFFFF 2px;
        cursor: pointer;
    }
    input {
        background-color: #d1d1d1;
        border: none;
        padding: 12px 15px;
        margin: 8px 0;
        width: 300px;
        border-radius: 15px;
    }
}
