@mixin ngx-layout() {
  @include media-breakpoint-down(is) {
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}
nb-toastr-container {
  z-index: 100000000 !important
}
