@import './themes';

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
      color: yellow;
      font-size: 1.5em;
      z-index: 10000;
    }
  }
  nb-toastr-container {
    z-index: 100000000 !important;
  }
}
