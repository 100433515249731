
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

h1 {
	font-weight: bold;
	margin: 0;
}

h2 {
	text-align: center;
	font-size: 3em;
	letter-spacing: 0.2em;
}

p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

span {
	font-size: 12px;
}

a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

button {
	border-radius: 20px;
	border: 1px solid #2d3192;
	background-color: #2d3192;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	margin-top: 20px
}

.span-form {
	font-size: small;
	margin-top: 50px;
	margin-bottom: 15px;
}

.span-form-large {
	font-size: large;
	margin-top: 50px;
	margin-bottom: 15px;
}

button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.msg-error {
	font-weight: bolder; 
	color: #FFFFFF;
}

form {
	background: #FF416C;
	background: -webkit-linear-gradient(to right, #FF416C, #2d3192);
	background: linear-gradient(to right, #FF416C, #2d3192);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.forgot {
    font-size: 1em;
    cursor: pointer;
}

.forgot a {
	color: #FFFFFF;
    text-decoration: none;
    font-size: 1em;
}

input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 300px;
	border-radius: 15px;
}

.container {
	margin-top: 5%;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
	0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 70%;
	max-width: 100%;
	min-height: 580px;
	height: 80%;
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
	background: #FF416C;
	background: -webkit-linear-gradient(to right, #FF416C, #2d3192);
	background: linear-gradient(to right, #FF416C, #2d3192);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
}


.title {
	color: #2d3192;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
	background: #FF416C;
	background: -webkit-linear-gradient(to right, #FF416C, #2d3192);
	background: linear-gradient(to right, #FF416C, #2d3192);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
}

.container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	background-color: #fff;
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	/* background: #FF416C;
	background: -webkit-linear-gradient(to right, #FF416C, #2d3192);
	background: linear-gradient(to right, #FF416C, #2d3192);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0; */
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
    cursor: pointer;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 100%;
    cursor: pointer;
}
#customBtn {
	display: inline-block;
	background: white;
	color: #444;
	width: 190px;
	border-radius: 5px;
	border: thin solid #888;
	box-shadow: 1px 1px 1px grey;
	white-space: nowrap;
  }
  #customBtn:hover {
	cursor: pointer;
  }
  span.label {
	font-family: serif;
	font-weight: normal;
  }
  span.icon {
	background: url('/identity/sign-in/g-normal.png') transparent 5px 50% no-repeat;
	display: inline-block;
	vertical-align: middle;
	width: 42px;
	height: 42px;
  }
  span.buttonText {
	display: inline-block;
	vertical-align: middle;
	padding-left: 42px;
	padding-right: 42px;
	font-size: 14px;
	font-weight: bold;
	/* Use the Roboto font that is loaded in the <head> */
	font-family: 'Roboto', sans-serif;
  }
  @media screen and (max-width: 1000px){

	.container {		
		border-radius: 10px;
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
		0 10px 10px rgba(0,0,0,0.22);
		position: relative;
		overflow: hidden;
		width: 100%;
		max-width: 100%;
		height: 680px;
	}

	.form-container {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
		transition: all 0.6s ease-in-out;
		background: #FF416C;
		background: -webkit-linear-gradient(to right, #FF416C, #2d3192);
		background: linear-gradient(to right, #FF416C, #2d3192);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0 0;
	}

	.overlay-container {
		display: none;
	}	
	input {
		background-color: #eee;
		border: none;
		padding: 12px 15px;
		margin: 8px 0;
		width: 300px;
		border-radius: 15px;
	}
  }
