@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "~bootstrap-icons/font/bootstrap-icons.css";

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import 'bootstrap/scss/bootstrap';
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

.maximized .title {
  display: none !important;
}

.minimized .title {
  display: flex !important;
  color: #666666 !important;
}
nb-card {
  border-radius: 1.5em !important;
  background-color: transparent !important;
  border: none !important;
}

.maximized nb-card-header {
  height: 40px !important;
  width: 180px !important;
  position: relative;
  z-index: 100000000000;
  background-color: transparent !important;
  border: none !important;
  right: -60% !important;
  margin-bottom: -35px;
}

// // .maximized nb-card-header {
// //   height: 40px !important;
// //   width: 180px !important;
// //   position: relative;
// //   z-index: 100000000000;
// //   border: none !important;
// //   right: -60% !important;
// //   margin-bottom: -35px;
// // }

.maximized .buttons {
  display: flex;
  justify-content: flex-end !important;
  align-items: center !important;
  position: relative !important;
  z-index: 999999999 !important;
  bottom: 50% !important;
  right: 0% !important;
}
.minimized nb-card-header {
  position: static;
  width: 300px !important;
  height: 40px !important;
  background-color: #FFFFFF !important;
  border-radius: 0.5em;
}
.full-screen {
  width: 450px !important;
  overflow: hidden !important;
  border-radius: 1.5em !important;
}
.minimized .buttons {
  display: flex;
  width: 1.5rem !important;
  justify-content: space-around !important;
  align-items: center !important;
  position: relative !important;
  z-index: 999999999 !important;
  bottom: 75% !important;
  right: 5% !important;
}

nb-card-body {
  border-radius: 1.5em !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  font-weight: 600 !important;
  padding-top: 0.3em;
}


.mat-mdc-form-field {
  font-size: 0.9em !important;
  height: 89px !important;

}

mat-form-field {
  background-color: transparent !important;
  width: 150px;
  position: absolute;
  top: 27%;
  right: 33%;
}

mat-form-field::before {
  background-color: transparent !important;
  width: 150px;
}

.mat-calendar-body-cell-content {
  width: 40% !important;
}

.mat-datepicker-content .mat-calendar {
  height: 370px !important
}

mat-calendar-body-cell-content mat-focus-indicator {
  width: 100px !important;
}

mat-form-field::after {
  background-color: transparent !important;
  width: 150px;
}

mat-datepicker-toggle {
  background-color: transparent !important;
}

mat-datepicker {
  background-color: transparent !important;
}

.input-mat {
  background-color: transparent !important;
}

.mdc-text-field--filled {
  background-color: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.marker-pin {
  width: 50px;
  height: 50px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}

.img-icon {
  object-fit: cover;
  width: 52px;
  position: absolute;
  z-index: 1000000;
  border-radius: 100%;
  top: 10%;
  left: -5%;
}
// to draw white circle
.marker-pin::after {
    content: '';
    width: 42px;
    height: 42px;
    margin: 3px 0 0 3px;
    background: #202435 !important;
    position: absolute;
    border-radius: 50%;
    left: 3%;
 }

// to align icon
.custom-div-icon i {
   position: absolute;
   width: 22px;
   font-size: 22px;
   left: 0;
   right: 0;
   margin: 10px auto;
   text-align: center;
}

.status-warning {
  color: #FFFFFF !important;
}

.toast-class {
  z-index: 999999999999999999999999999999999 !important;
}

nb-toastr-container {
  z-index: 99999999999999999999999999999999999 !important;
}

.leaflet-bar {
  border: none !important;
  background-color: transparent !important
}
.leaflet-left{
  border: none !important;
  background-color: transparent !important

}
.leaflet-top {
  border: none !important;
  background-color: transparent !important
}
.leaflet-control-zoom{
  border: none !important;
  box-shadow: none !important
}
.leaflet-control {
  border: none !important;
  box-shadow: none !important;
}

.leaflet-control-zoom-in {
  margin: 10px 0px 0px 0px  !important
}
.leaflet-control-zoom-out {
  margin: 0  !important;
}

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
