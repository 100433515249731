@media (min-width: 426px) {
	
.mage-mobile {
	display: none;
}
.instructions {
	color: black;
	z-index: 10000 !important;
	position: absolute;
	left: 48%;
	top: 55%;
	width: 350px
}

.instructions ul {
	font-size: 10px;
}

.instructions ul li {
	color: #494949b2
}

.landing {
	position: relative;
	z-index: 2;
	width: 710px;
	height: 520px;
	margin: 25px auto;
	box-shadow: 0 3px 30px rgba(0, 0, 0, 0.3);
	overflow: hidden;
	
	& .logo svg {
	  fill: #1E1C45;
	}
	
	& .base {
	  position: absolute;
	  top: 0;
	  left: 0;
	  z-index: 1;
	  width: 100%;
	  height: 100%;
	  padding: 38px;
	  color: #2d3192;
	  background: linear-gradient(25deg, #2d3192, #FF416C);
	}
	  
	& .image {
	  display: none;
	}
	
  }
  
  
  /*--------------------
  Copy
  --------------------*/
  .copy {
	position: relative;
	z-index: 2;
	
	& .title {
	  font-size: 52px;
	  font-weight: 800;
	  text-transform: uppercase;
	  line-height: 1;
	  margin-bottom: 30px;
	  margin-left: -3px;
	  background-image: linear-gradient(45deg, #FFFFFF, #FFFFFF);
	  background-clip: text;
	  -webkit-text-fill-color: transparent;
	  letter-spacing: 4px;
	  
	  
	  & small {
		font-size: 18px;
		font-weight: 800;
		text-transform: uppercase;
		margin-top: 50px;
		line-height: 1;
		display: block;
		text-indent: 3px;
	  }
	}
	
	& .text {
	  color: #FFFFFF;
	  width: 400px;
	  font-size: 90%;
	}
	
	& .cta {
	  cursor: pointer;
	  background: #fff;
	  color: #2d3192;
	  text-transform: uppercase;
	  display: inline-block;
	  border-radius: 30px;
	  text-decoration: none;
	  padding: 10px 30px;
	  font-weight: 600;
	  position: absolute;
	  top: 105%
	}
	
  }
  
  
  /*--------------------
  Overlay
  --------------------*/
  .base.overlay {
	z-index: 2;
	clip-path: polygon(290px 0, 790px 0, 710px 530px, 350px 550px);
	color: #fff;
	background: linear-gradient(15deg, #ffffff, #ffffff);
	transition: all 1.1s ease-in-out;
	transform-origin: 0 0;
	background-color: #fff;
	
	&.over {
	  clip-path: polygon(-1750px 0, 710px 0, 710px 630px, 0 710px);
	}
	
	& .logo svg {
	  fill: #fff;
	}
	  
	& .image {
	  display: block;
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  top: 0;
	  right: 0;
	  z-index: 1;
	  
	  &::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		z-index: 1;
		/* background: linear-gradient(42deg, #2d3192, #FF416C); */
		opacity: 0.8;
	  }
	}
	
	& .title {
		background-image: linear-gradient(45deg, #2d3192, #FF416C);
	}
	
	& .text {
	  color: #2d3192;
	}
	
	& .cta {
	  background: linear-gradient(25deg, #2d3192, #FF416C);
	  color: #fff;
	}
  }

  .img-trazapp {
	border-style: none;
	width: 250px;
	position: relative;
	left: 55%;
	top: 5%
  }

  .img-container {
	width: 250px;
	height: 250px;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	border-radius: 15%;
  }

  .info-container {
	background-color: #fff;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-end;
  }
  .info-container p {
	font-weight: 600;
	font-size: 1rem;
	width: 50%;
	position: absolute;
	top: 60%
  }
  .qr-code-without-border {
	margin-top: 2%;
	padding: 10px;
	border-radius: 10%;
	width: 200px;
	height: 200px;
  }

  .landing .image-mobile {
	display: none;
	margin-bottom: 20px;
  }

  .container-element-mobile {
	padding: 20px;
	padding-left: 36px;
	text-align: center;
	display: flex;
	color: white;
  }

  .color-link-qr {
	color: #2d3192 !important;
	cursor: pointer;
	background: white;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 30px;
  }

  a:hover {
	text-decoration: none;
  }

  .mobile-loading {
	left: 15% !important;
	top: -67% !important;
}

}
  .flip-animation {
		-webkit-backface-visibility: visible;
		backface-visibility: visible;
		-webkit-animation-name: flip;
		animation-name: flip;
		-webkit-animation-duration: 5s;
		animation-duration: 6s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}
	@-webkit-keyframes flip {
		0% {
		-webkit-transform: perspective(300px) rotate3d(0, 1, 0, -360deg);
		transform: perspective(300px) rotate3d(0, 1, 0, -360deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		}
		40% {
		-webkit-transform: perspective(300px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
		transform: perspective(300px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		}
		50% {
		-webkit-transform: perspective(300px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
		transform: perspective(300px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		}
		80% {
		-webkit-transform: perspective(300px) scale3d(.95, .95, .95);
		transform: perspective(300px) scale3d(.95, .95, .95);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		}
		100% {
		-webkit-transform: perspective(300px);
		transform: perspective(300px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		}
	}
	@keyframes flip {
		0% {
		-webkit-transform: perspective(300px) rotate3d(0, 1, 0, -360deg);
		transform: perspective(300px) rotate3d(0, 1, 0, -360deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		}
		40% {
		-webkit-transform: perspective(600px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
		transform: perspective(600px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
		-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
	}
	50% {
	-webkit-transform: perspective(500px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
	transform: perspective(500px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
	-webkit-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
	}
	80% {
	-webkit-transform: perspective(450px) scale3d(.95, .95, .95);
	transform: perspective(450px) scale3d(.95, .95, .95);
	-webkit-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
	}
	100% {
	-webkit-transform: perspective(400px);
	transform: perspective(400px);
	-webkit-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
	}
	} 

	@media (max-width: 425px) {
		.mobile-loading {
			left: 32vw !important;
			top: -149% !important;
		}

		.qr-code-without-border {
			margin-top: 2%;
			padding: 10px;
			border-radius: 10%;
			width: 200px;
			height: 200px;
		  }
		/* .instructions {
			color: black;
			z-index: 10000 !important;
			position: relative;
			width: 90vw;
			margin-top: 20px;
			text-align: center;
		} */
	
		.landing {
			position: relative;
			border-radius: 20px;
			z-index: 2;
			width: 90vw;
			height: auto;
			margin: 25px auto;
			box-shadow: 0 3px 30px rgba(0, 0, 0, 0.3);
			overflow: hidden;
			background: white;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 20px 0;
			& .cta {
				cursor: pointer;
				background: #fff;
				color: #2d3192;
				text-transform: uppercase;
				display: inline-block;
				border-radius: 30px;
				text-decoration: none;
				padding: 10px 30px;
				font-weight: 600;
				background: linear-gradient(25deg, #2d3192, #FF416C);
				color: #fff;
			  }
		}
	
		.landing .base {
			position: relative;
			width: 100%;
			height: auto;
			padding: 0;
			background: none; /* Elimina el gradiente de color */
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	
		.landing .image {
			display: none;
			margin-bottom: 20px;
		}
		.landing .image-mobile {
			display: block;
			/* margin-bottom: 20px; */
		}
	
		.landing .img-container {
			/*  width: 200px; */
			min-height: 200px; 
			min-width: 200px;
			padding: 10px;
			background-color: white;
			border-radius: 20px;
			padding: 20px;
			border: 1px solid #2d3192;
		}

		.landing .img-trazapp {
			margin-top: 5em;
			width: 100px;
			padding: 10px;
			display: none;
		}


		.instructions {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
		}

		
		.instructions ul {
			margin: 0;
			padding-left: 0;
		}

		.instructions ul li {
			margin-bottom: 10px;
			padding-right: 2em;
			padding-left: 2em;
			list-style-position: inside;
		}

		.base .overlay {
			display: none;
		}

		.container-element-mobile {
			text-align: center;
		}

		a:hover {
			text-decoration: none;
		}

		.download-container {
			padding-top: 10px;
			text-align: center;
			font-family: Arial, sans-serif;
		}
		
		.playstore-link {
			display: inline-flex;
			align-items: center;
			text-decoration: none;
			color: #464545;
			font-weight: bold;
			margin-top: 5px;
		}
		
		.playstore-icon {
			width: 8vw;
			height: 20px;
			margin-right: 8px;
		}
		
		.playstore-link span {
			vertical-align: middle;
		}
		
	}
	
  