 .pulse {
     animation: pulsate 1s ease-out;
     -webkit-animation: pulsate 1s ease-out;
     -webkit-animation-iteration-count: infinite;
     opacity: 0.0
 }


 @keyframes pulsate {
     0% {
         transform: opacity 0.0;
     }

     50% {
         opacity: 1.0;
     }

     100% {
         transform: opacity 0.0;
     }
 }

 .css-icon {
     font-family: Arial, Helvetica, sans-serif;
 }


 .center {
     position: absolute;
     top: 45%;
     left: 50%;
     transform: translate(-50%, -50%);
 }

 .green-pulse {
     width: 8px;
     height: 8px;
     line-height: 1px;
     text-overflow: ellipsis;
     white-space: nowrap;
     overflow: hidden;
     max-width: 8px;
     background: transparent;
     border-radius: 50%;
     color: rgb(255, 255, 255);
     text-align: center;
     font-family: Arial;
     animation: animate_green 2s linear infinite;
     font-size: 100%;
     text-transform: uppercase;
     font-weight: bold;
     opacity: 1;
 }

 @keyframes animate_green {
     0% {
         box-shadow: 0 0 0 0 rgba(0, 63, 0, 0.7), 0 0 0 0 rgba(0, 63, 0, 0.7);
     }

     40% {
         box-shadow: 0 0 0 50px rgba(43, 126, 11, 0), 0 0 0 0 rgba(43, 126, 11, 0.7);
     }

     80% {
         box-shadow: 0 0 0 50px rgba(62, 206, 22, 0), 0 0 0 30px rgba(62, 206, 22, 0);
     }

     100% {
         box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 30px rgba(255, 0, 64, 0);
     }
 }